import React, { useEffect } from 'react';
import useBodyClass from './hooks';

export default function CollectionHome(props) {
  const { collection } = props;
  useBodyClass('collection-home');
  useEffect(() => {
    document.title = collection.title;
  }, [collection]);
  return (<></>);
}
