import React, { useEffect } from 'react';
import useBodyClass from './hooks';
import collections from './data/collections.json';

export default function Home() {

  useBodyClass('root-home');

  useEffect(() => {
    document.title = 'Gebbie Ledo – Fotos de nuestra familia';
  }, [])

  return (
    <div className="collection-list">
      {collections.map((c, i) =>
        (<div className="collection-item" key={i}>
          <a href={`/${c.path}`}>
            <img src={`/images/${c.path}-bg-photo.jpg`} alt={c.name} />
            <h5 style={{}}>{c.name}</h5>
          </a>
        </div>
        ))}
    </div>
  )
}
