import React from 'react';
import GalleryMenu from './gallery-menu';
import { useParams } from 'react-router-dom';
import useBodyClass from './hooks';
// import { Helmet } from 'react-helmet';

function Layout(props) {
  const { children, collection, galleries, path } = props;
  const { galleryPath } = useParams();

  useBodyClass(`collection-${collection?.path || 'none'}`);
  
  const Logo = () => {

    let src = `/images/${collection?.logo || 'gebbie-ledo-logo@2x.png'}`;

    // Different logo for collection home screen
    if (collection && !galleryPath && collection.logoHome)
      src = `/images/${collection.logoHome}`;

    return (
      <img width="1030" height="318" src={src}
        alt="Lali y Rich" itemProp="logo" sizes="(max-width: 1030px) 100vw, 1030px" />
    );
  };

  return (
    <>
      {/* <Helmet>
        <meta property="og:url" content={`https://www.gebbieledo.com/${collection?.path || ''}`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={collection?.title || 'Gebbie Ledo'} />
        <meta property="og:image" content={`https://www.gebbieledo.com/images/${collection?.path || 'site'}-og-image.jpg`} />
        <meta property="og:description" content={collection?.description || 'Fotos de nuestra familia'} />
      </Helmet> */}
        
      <div id="page" className="site">

        {path !== '/' && <GalleryMenu galleries={galleries} />}

        <header id="header" className="site-header" role="banner">
          <div className="site-branding">
            <div className="logo-container">
              <Logo />
            </div>
          </div>
        </header>

        <main id="primary" className="site-content">
          <div className="site-content__inner">
            <article className="page type-page status-publish hentry">
              {children}
            </article>
          </div>
        </main>
      </div>

      <footer id="footer" className="site-footer" role="contentinfo">
        <div className="site-info">
          <div className="site-info__author">
            {collection?.footer && collection.footer.map((f, i) => <div key={i}>{f}</div>)}
          </div>
        </div>
      </footer>
    </>
  );
}

export default Layout;