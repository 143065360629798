import React, { useEffect, useState, useRef } from 'react';
import * as PhotoSwipe from 'photoswipe';
import * as PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

function Gallery(props) {

  const photoswipeEl = useRef(null);
  const downloadEl = useRef(null);
  const { collection, gallery, hover } = props;
  const [photoswipeItems, setPhotoswipeItems] = useState([]);
  const [photoswipe, setPhotoswipe] = useState(null);
  const [fullPath, setFullPath] = useState('');
  const [downloadUrl, setDownloadUrl] = useState('');

  useEffect(() => {
    document.title = `${gallery.name} - ${collection.title}`
   }, [gallery.name, collection.title]);

  useEffect(() => {
    const downloadPhoto = () => {

      let i = photoswipe.getCurrentIndex();
      setDownloadUrl(`${fullPath}/${gallery.photos[i].src}`);
      setTimeout(() => downloadEl.current.click());
    }
    
    document.addEventListener('download-photo', downloadPhoto);
    return () => {
      document.removeEventListener('download-photo', downloadPhoto);
    }
  }, [photoswipe]);
    
  useEffect(() => {
    const fp = `/collections/${collection.path}/galleries/${gallery.path}`;
    setFullPath(fp);
    const items = gallery.photos.map(p => ({ src: p.html ? undefined : `${fp}/web/${p.src}`, w: p.w, h: p.h, html: p.html }));
    setPhotoswipeItems(items);
  }, [gallery]);

  const handleHoverOff = () => { };

  const view = (index = 0) => {
    let options = {
      index,
      barsSize: { top: 0, bottom: 'auto' },
      preload: [1, 2], // one behind, two ahead
      captionEl: false,
      counterEl: false,
      hideScroll: true,
      closeOnScroll: false,
      loop: false
    };

    const ps = new PhotoSwipe(photoswipeEl.current, PhotoSwipeUI_Default, photoswipeItems, options);
    setPhotoswipe(ps);
    ps.init();
  };

  const getUrlPid = () => {
    let url = window.location.href;
    let re = /&pid=([\d]+)/g;
    let m = re.exec(url);
    return m && +m[1] ? +m[1] : undefined;
  }

  useEffect(() => {
    if (!photoswipeItems.length) return;

    // Url in PID?
    const urlPhotoId = getUrlPid();
    if (urlPhotoId) {
      view(urlPhotoId - 1); // Photo ID is 1-based, index is 0-based
    }

  }, [photoswipeItems]);

  const Photos = () =>
    gallery.photos.map((p, i) => (
      <div key={i} className="frame">
        <button className="link" onClick={() => view(i)}>
          <div className="mount1">
            <div className="mount2">
              <div className="mount3">
                {p.html && <span className="fa-stack fa-2x video-overlay" onMouseOut={handleHoverOff}>
                  <i className="fas fa-circle fa-stack-2x"></i>
                  <i className="fas fa-play fa-stack-1x"></i>
                </span>}
                <img className={hover === i ? 'hover' : ''}
                  src={`/collections/${collection.path}/galleries/${gallery.path}/thumbnails/${p.src}`} alt={`${gallery.name} ${(i + 1)}`} />
              </div>
            </div>
          </div>
        </button>
        {/* <div>{p.src}</div> */}
      </div>
    ));

  return (
    <>
      <header className="entry-header">
        <h1 className="entry-title">{gallery.name}</h1>
      </header>

      <div className="entry-content">
        <div className="gallery">
          <Photos />
        </div>
      </div>

      <a href={downloadUrl} target="_blank" rel="noopener noreferrer" id="hidden-download-link" download ref={downloadEl}>&nbsp;</a>

      {/* Root element of PhotoSwipe. Must have class pswp. */}
      <div className="pswp" tabIndex="-1" role="dialog" aria-hidden="true" ref={photoswipeEl}>

        {/* Background of PhotoSwipe. 
         It's a separate element as animating opacity is faster than rgba(). */}
        <div className="pswp__bg"></div>

        {/* Slides wrapper with overflow:hidden. */}
        <div className="pswp__scroll-wrap">

          {/* Container that holds slides. 
            PhotoSwipe keeps only 3 of them in the DOM to save memory.
            Don't modify these 3 pswp__item elements, data is added later on. */}
          <div className="pswp__container">
            <div className="pswp__item"></div>
            <div className="pswp__item"></div>
            <div className="pswp__item"></div>
          </div>

          {/* Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. */}
          <div className="pswp__ui pswp__ui--hidden">
            <div className="pswp__top-bar">
              <div className="pswp__counter"></div>
              <div className="pswp__actions">
                <button className="pswp__button pswp__button--close" title="Close (Esc)"></button>
                <button className="pswp__button pswp__button--download" title="Download photo"></button>
                <button className="pswp__button pswp__button--play" title="Play/pause slideshow"></button>
              </div>
               
              <div className="pswp__preloader">
              </div>
            </div>

            <div className="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
              <div className="pswp__share-tooltip"></div>
            </div>
            <button className="pswp__button pswp__button--arrow--left" title="Previous photo"></button>
            <button className="pswp__button pswp__button--arrow--right" title="Next photo"></button>
            <div className="pswp__caption"><div className="pswp__caption__center"></div></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Gallery;