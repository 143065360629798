import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Link, useLocation, useParams } from 'react-router-dom';

export default function GalleryMenu(props) {

  const { galleries } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const siteMenu = useRef(null);
  const location = useLocation();
  const { collectionPath } = useParams();

  useEffect(() => {
    const clickOutside = ($event) => {
      if (!siteMenu.current.contains($event.target)) {
        setIsMenuOpen(false);
      }
    }

    const escapeMenu = ($event) => {
      if ($event.key === 'Escape') {
        setIsMenuOpen(false);
      }
    }
      
    document.addEventListener('click', clickOutside);
    document.addEventListener('keyup', escapeMenu);
    return () => {
      document.removeEventListener('click', clickOutside);
      document.removeEventListener('keyup', escapeMenu);
    }
  }, []);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  }

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  }

  const primaryMenuClasses = classNames('site-menu__inner', { 'is-open': isMenuOpen });
  // const homeMenuItemClasses = classNames('menu-item', { 'current-menu-item': location.pathname === `/${collectionPath}` });
  const otherMenuItemClasses = g => classNames('menu-item', { 'current-menu-item': location.pathname.endsWith(g.path) });

  return (
    <nav id="site-menu" className="site-menu" role="navigation" ref={siteMenu}>
      <button id="site-menu-open" className="site-menu-toggle" onClick={handleMenuToggle}>Menu <span>&#9776;</span></button>

      <div id="primary-menu" className={primaryMenuClasses}>
        <button id="site-menu-close" className="site-menu-toggle site-menu-toggle--close" onClick={handleCloseMenu}><span>&times;</span></button>
        <div className="menu-main-container">
          <ul id="menu-main" className="menu">

            <li className="menu-item">
              <Link to={`/`} onClick={handleCloseMenu} style={{ color: 'rgba(61, 61, 61, 0.5)' }}>Inicio</Link>
            </li>
            {/* <li className={homeMenuItemClasses}>
              <Link to={`/${collectionPath}`} onClick={handleCloseMenu}>Inicio</Link>
            </li> */}
            {galleries.map((g, i) => (
              <li key={i} className={otherMenuItemClasses(g)}>
                <Link to={`/${collectionPath}/${g.path}`} onClick={handleCloseMenu}>{g.name}</Link>
              </li>))}
          </ul>
        </div>
      </div>
    </nav>
  );

}
