import React from 'react';
import './styles/index.scss';
import Layout from './layout';
import bodaGalleries from './data/boda.json';
import { Switch, Route, useParams, useLocation } from 'react-router-dom';
import Gallery from './gallery';
import CollectionHome from './collection-home';
import './polyfills';
import Home from './home';
import collections from './data/collections';
import bautizoGalleries from './data/bautizo.json';

function App() {
  const location = useLocation();
  
  const findCollection = (path) => collections.find(c => c.path === path);
  const getGalleries = (collection) => {
    switch (collection.path) {
      case "boda":
        return bodaGalleries;
      default:
        return bautizoGalleries;
    }
  }
  
  const RenderCollectionHome = () => {
    const { collectionPath } = useParams();
    const collection = findCollection(collectionPath);
    return collection ? <CollectionHome collection={collection} /> : <h3>{collectionPath} not found</h3>;
  };

  const RenderGallery = () => {
    const { collectionPath, galleryPath } = useParams();
    const collection = findCollection(collectionPath);
    if (!collection) return <h3>{collectionPath} not found</h3>;
    const gallery = getGalleries(collection).find(g => g.path === galleryPath);
    return gallery ? <Gallery collection={collection} gallery={gallery} /> : <h3>{galleryPath} not found</h3>
  };

  const RenderLayout = (props) => {
    const { children } = props;
    
    const { collectionPath } = useParams();
    const collection = findCollection(collectionPath);
    if (!collection) return <h3>{collectionPath} not found</h3>;

    const galleries = getGalleries(collection);
    
    return (
      <Layout collection={collection} galleries={galleries} path={location.pathname}>
        {children}
      </Layout>
    );
  };

  return (
    <Switch>
      <Route path="/:collectionPath/:galleryPath">
        <RenderLayout>
          <RenderGallery />
        </RenderLayout>
      </Route>
      <Route path="/:collectionPath">
        <RenderLayout>
          <RenderCollectionHome />
        </RenderLayout>
      </Route>
      <Route path="/">
        <Layout path={location.pathname}>
          <Home />
        </Layout>
      </Route>
    </Switch>
  );
}

export default App;
